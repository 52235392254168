<template>
  <div class="anggota-detail">
    <h1 class="mb-5">Detail Anggota</h1>

    <transition name="fade" mode="out-in">
      <div class="row gutters-sm" v-if="!isLoading">
        <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center text-center">
                <div class="circular--landscape" v-if="fotoDiri">
                  <img :src="'data:image/png;base64,' + fotoDiri" />
                </div>
                <img
                  v-else
                  src="https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"
                  alt="Foto profil anggota"
                  class="rounded-circle"
                  width="150"
                  height="150"
                />
                <div class="mt-3">
                  <h4>{{ anggota.nama_lengkap }}</h4>
                  <status-badge
                    :status="anggota.status_anggota"
                    class="mb-2"
                  ></status-badge>
                  <div
                    class="d-flex gap-2 justify-content-between"
                    v-if="
                      anggota.status_anggota === 'WAITING APPROVAL' &&
                      (currentUser.role === 'Super User' ||
                        currentUser.role === 'PAC')
                    "
                  >
                    <button
                      class="btn btn-outline-success"
                      @click="approveAnggota"
                    >
                      Setujui
                    </button>
                    <button
                      class="btn btn-outline-danger"
                      @click="rejectAnggota"
                    >
                      Tolak
                    </button>
                  </div>

                  <div
                    class="d-flex justify-content-center"
                    v-if="currentUser.role === 'Super User'"
                  >
                    <!-- Konfirmasi Pembayaran -->
                    <button
                      class="btn btn-outline-success"
                      @click="confirmPayment"
                      v-if="anggota.status_anggota === 'WAITING PAYMENT'"
                    >
                      Konfirmasi Pembayaran
                    </button>

                    <!-- Konfirmasi Pencetakan -->
                    <button
                      class="btn btn-outline-success"
                      @click="confirmProduction"
                      v-if="anggota.status_anggota === 'WAITING PRODUCTION'"
                    >
                      Konfirmasi Pencetakan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <ul class="list-group list-group-flush">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Agama</h6>
                <span class="text-secondary">{{ anggota.agama || "-" }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Jenis Kelamin</h6>
                <span class="text-secondary">{{
                  anggota.jenis_kelamin || "-"
                }}</span>
              </li>

              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Pekerjaan</h6>
                <span class="text-secondary">{{
                  (anggota.Pekerjaan && anggota.Pekerjaan.nama) || "-"
                }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Hobi</h6>
                <span class="text-secondary">{{
                  (anggota.Hobi && anggota.Hobi.nama) || "-"
                }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Tanggal Lahir</h6>
                <span class="text-secondary">{{
                  localDate(anggota.tanggal_lahir) || "-"
                }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Tanggal Registrasi</h6>
                <span class="text-secondary">{{
                  localDate(anggota.createdAt) || "-"
                }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Tanggal Disetujui</h6>
                <span class="text-secondary">{{
                  localDate(anggota.approved_at)
                }}</span>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Disetujui Oleh</h6>
                <span class="text-secondary">{{
                  `${(anggota.approver && anggota.approver.email) || "-"} (${
                    anggota.approver && anggota.approver.role
                  })`
                }}</span>
              </li>
            </ul>
          </div>
          <div class="card mt-3">
            <ul class="list-group list-group-flush">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Foto Identitas</h6>
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showFotoIdentitas"
                  :disabled="isLoadFoto"
                >
                  {{ isLoadFoto ? "Diproses" : "Lihat foto" }}
                </button>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Foto memegang Identitas</h6>
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showFotoMemegangIdentitas"
                  :disabled="isLoadFoto"
                >
                  {{ isLoadFoto ? "Diproses" : "Lihat foto" }}
                </button>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Foto Diri</h6>
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showFotoDiri"
                  :disabled="isLoadFoto"
                >
                  {{ isLoadFoto ? "Diproses" : "Lihat foto" }}
                </button>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <h6 class="mb-0">Bukti Pembayaran</h6>
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="showBuktiPembayaran"
                  v-if="anggota.bukti_pembayaran"
                  :disabled="isLoadFoto"
                >
                  {{ isLoadFoto ? "Diproses" : "Lihat foto" }}
                </button>
                <small v-else class="caption">Belum di upload</small>
              </li>
            </ul>
          </div>
          <div class="card mt-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex flex-wrap">
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    @click="resendEmailRegisterSuccess"
                  >
                    Kirim ulang email pendaftaran disetujui
                  </button>
                </div>
              </li>
              <li class="list-group-item d-flex flex-wrap">
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    @click="resendEmailApproved"
                  >
                    Kirim ulang email info pembayaran
                  </button>
                </div>
              </li>
              <li class="list-group-item d-flex flex-wrap">
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    @click="resendEmailOnProduction"
                  >
                    Kirim ulang email KTA dalam pencetakan
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">No. Identitas</h6>
                </div>
                <div class="col-sm-3 text-secondary">
                  {{ anggota.no_identitas }}
                </div>
                <div class="col-sm-3">
                  <h6 class="mb-0">Jenis Identitas</h6>
                </div>
                <div class="col-sm-3 text-secondary">
                  {{ anggota.jenis_identitas }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Nama Lengkap</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.nama_lengkap }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Nama Panggilan</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.nama_panggilan }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">No. KTA</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.nomor_kta || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ anggota.email }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">No. HP</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.no_hp || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Jenis Atribut</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.jenis_atribut || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Ukuran Atribut</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.ukuran_atribut || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">QR Code</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{ anggota.qr_code || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <router-link
                    class="btn btn-outline-dark"
                    :to="{
                      name: 'admin-anggota-edit',
                      params: { id: anggota.id },
                    }"
                  >
                    <i class="bi bi-pencil"></i> Edit Data</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row gutters-sm">
            <div class="col-12 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class="nav-link active"
                        id="nav-domisili-origin-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-domisili-origin"
                        type="button"
                        role="tab"
                        aria-controls="nav-domisili-origin"
                        aria-selected="true"
                      >
                        Domisili sesuai KTP
                      </button>
                      <button
                        class="nav-link"
                        id="nav-current-domisili-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-current-domisili"
                        type="button"
                        role="tab"
                        aria-controls="nav-current-domisili"
                        aria-selected="false"
                      >
                        Domisili saat ini
                      </button>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-domisili-origin"
                      role="tabpanel"
                      aria-labelledby="nav-domisili-origin-tab"
                    >
                      <div class="px-3 py-3">
                        <div class="row no-gutters">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Provinsi</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.Propinsi && anggota.Propinsi.nama) || ""
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kota</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{ (anggota.Kotum && anggota.Kotum.nama) || "" }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kecamatan</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.Kecamatan && anggota.Kecamatan.nama) ||
                              ""
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kelurahan</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.Kelurahan && anggota.Kelurahan.nama) ||
                              "-"
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Alamat</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{ anggota.alamat }}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-current-domisili"
                      role="tabpanel"
                      aria-labelledby="nav-current-domisili-tab"
                    >
                      <div class="px-3 py-3">
                        <div class="row no-gutters">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Provinsi</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.current_domisili_propinsi &&
                                anggota.current_domisili_propinsi.nama) ||
                              "-"
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kota</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.current_domisili_kota &&
                                anggota.current_domisili_kota.nama) ||
                              "-"
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kecamatan</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.current_domisili_kecamatan &&
                                anggota.current_domisili_kecamatan.nama) ||
                              "-"
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Kelurahan</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.current_domisili_kelurahan &&
                                anggota.current_domisili_kelurahan.nama) ||
                              "-"
                            }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Alamat</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{ anggota.current_domisili_alamat }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Domisili Luar negeri</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{ anggota.is_abroad ? "Ya " : "Tidak" }}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Negara</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {{
                              (anggota.Country && anggota.Country.name) || "-"
                            }}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row gutters-sm">
            <div class="col-sm-6 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h6 class="d-flex align-items-center mb-3">
                    <i class="bi bi-person-check"></i> &nbsp; Ahli Waris 1
                  </h6>
                  <small>NIK</small>
                  <p>{{ anggota.ahli_waris_1_nik || "-" }}</p>
                  <small>Nama</small>
                  <p>{{ anggota.ahli_waris_1_nama || "-" }}</p>
                  <small>No. HP</small>
                  <p>{{ anggota.ahli_waris_1_no_hp || "-" }}</p>
                  <small>Hubungan Keluarga</small>
                  <p>{{ anggota.ahli_waris_1_hubungan || "-" }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h6 class="d-flex align-items-center mb-3">
                    <i class="bi bi-person-check"></i> &nbsp; Ahli Waris 2
                  </h6>
                  <small>NIK</small>
                  <p>{{ anggota.ahli_waris_2_nik || "-" }}</p>
                  <small>Nama</small>
                  <p>{{ anggota.ahli_waris_2_nama || "-" }}</p>
                  <small>No. HP</small>
                  <p>{{ anggota.ahli_waris_2_no_hp || "-" }}</p>
                  <small>Hubungan Keluarga</small>
                  <p>{{ anggota.ahli_waris_2_hubungan || "-" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <detail-placeholder v-else />
    </transition>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <button class="modal__close btn" @click="showModal = false">
        <i class="bi bi-x"></i>
      </button>
      <span class="modal__title">{{ fotoModalTitle }}</span>
      <div class="modal__content">
        <img
          :src="'data:image/png;base64,' + foto"
          alt="Foto Identitas"
          width="100%"
        />
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { anggotaService } from "@/services";
import DetailPlaceholder from "./DetailPlaceholder.vue";
import StatusBadge from "./StatusBadge.vue";
import { authenticationService } from "@/services";

export default {
  name: "AnggotaDetail",
  components: {
    DetailPlaceholder,
    StatusBadge,
  },
  data() {
    return {
      isLoading: true,
      isLoadFoto: false,
      anggota: {},
      foto: "",
      fotoDiri: "",
      fotoModalTitle: "",
      showModal: false,
      currentUser: authenticationService.currentUserValue,
    };
  },
  methods: {
    localDate(date) {
      if (date === null) return "-";
      const date2 = date != null && new Date(Date.parse(date));
      return new Intl.DateTimeFormat("id-ID", {
        dateStyle: "full",
        timeStyle: "long",
      }).format(date2);
    },
    async showBuktiPembayaran() {
      this.isLoadFoto = true;
      try {
        const response = await anggotaService.getFotoBuktiPembayaran(
          this.$route.params.id
        );
        const buffer = Buffer.from(response.data).toString("base64");
        this.foto = buffer;
        this.fotoModalTitle = "Foto Bukti Pembayaran";
        this.isLoadFoto = false;
        this.showModal = true;
      } catch (error) {
        this.$toast.error("Foto tidak ditemukan.");
        this.isLoadFoto = false;
      }
    },
    async showFotoIdentitas() {
      this.isLoadFoto = true;
      try {
        const response = await anggotaService.getFotoIdentitas(
          this.$route.params.id
        );
        const buffer = Buffer.from(response.data).toString("base64");
        this.foto = buffer;
        this.fotoModalTitle = "Foto Identitas";
        this.isLoadFoto = false;
        this.showModal = true;
      } catch (error) {
        this.$toast.error("Foto tidak ditemukan.");
        this.isLoadFoto = false;
      }
    },
    async showFotoMemegangIdentitas() {
      this.isLoadFoto = true;
      try {
        const response = await anggotaService.getFotoMemegangIdentitas(
          this.$route.params.id
        );
        const buffer = Buffer.from(response.data).toString("base64");
        this.foto = buffer;
        this.fotoModalTitle = "Foto Memegang Identitas";
        this.isLoadFoto = false;
        this.showModal = true;
      } catch (error) {
        this.$toast.error("Foto tidak ditemukan.");
        this.isLoadFoto = false;
      }
    },
    async showFotoDiri() {
      this.isLoadFoto = true;
      try {
        const response = await anggotaService.getFotoDiri(
          this.$route.params.id
        );
        const buffer = Buffer.from(response.data).toString("base64");
        this.foto = buffer;
        this.fotoModalTitle = "Foto Diri";
        this.isLoadFoto = false;
        this.showModal = true;
      } catch (error) {
        this.$toast.error("Foto tidak ditemukan.");
        this.isLoadFoto = false;
      }
    },
    async loadData() {
      anggotaService.getById(this.$route.params.id).then((response) => {
        this.anggota = response.data;
        this.isLoading = false;
      });

      try {
        const response = await anggotaService.getFotoDiri(
          this.$route.params.id
        );
        const buffer = Buffer.from(response.data).toString("base64");
        this.fotoDiri = buffer;
      } catch (error) {
        this.$toast.error("Foto tidak ditemukan.");
        this.isLoadFoto = false;
      }
    },
    approveAnggota() {
      anggotaService
        .approve(this.anggota.id)
        .then(() => {
          this.$toast.success(`Anggota berhasil disetujui`);
          this.loadData();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    rejectAnggota() {
      anggotaService
        .reject(this.anggota.id)
        .then(() => {
          this.$toast.success(`Anggota berhasil ditolak`);
          this.loadData();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    confirmPayment() {
      anggotaService
        .confirmPayment(this.anggota.id)
        .then(() => {
          this.$toast.success(`Pembayaran berhasil dikonfirmasi`);
          this.loadData();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    confirmProduction() {
      anggotaService
        .confirmProduction(this.anggota.id)
        .then(() => {
          this.$toast.success(`Pencetakan berhasil dikonfirmasi`);
          this.loadData();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    resendEmailApproved() {
      anggotaService
        .resendEmailInfoPembayaran(this.anggota.id)
        .then(() => {
          this.$toast.success(`Email berhasil dikirim`);
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    resendEmailOnProduction() {
      anggotaService
        .resendEmailInfoKTAOnProgress(this.anggota.id)
        .then(() => {
          this.$toast.success(`Email berhasil dikirim`);
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    resendEmailRegisterSuccess() {
      anggotaService
        .resendEmailInfoApproved(this.anggota.id)
        .then(() => {
          this.$toast.success(`Email berhasil dikirim`);
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.anggota-detail {
  padding: 3rem 1.5rem;
}

.circular--landscape {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.circular--landscape img {
  width: auto;
  height: 100%;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
