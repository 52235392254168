<template>
  <span class="badge" :class="statusColor">{{ statusText }}</span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    statusColor() {
      switch (this.status) {
        case "WAITING APPROVAL":
          return "bg-warning";
        case "WAITING PAYMENT":
          return "bg-primary";
        case "WAITING PRODUCTION":
          return "bg-secondary";
        case "APPROVED":
          return "bg-success";
        case "REJECTED":
          return "bg-danger";

        default:
          return "bg-info";
      }
    },
    statusText() {
      switch (this.status) {
        case "WAITING APPROVAL":
          return "Menunggu Persetujuan";
        case "WAITING PAYMENT":
          return "Menunggu Pembayaran";
        case "WAITING PRODUCTION":
          return "Sedang Dicetak";
        case "APPROVED":
          return "Disetujui";
        case "REJECTED":
          return "Ditolak";

        default:
          return "-";
      }
    },
  },
};
</script>

<style scoped></style>
