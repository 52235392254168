<template>
  <div class="row gutters-sm">
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="card-body placeholder-glow">
          <div class="d-flex flex-column align-items-center text-center">
            <img
              class="rounded-circle placeholder bg-dark"
              width="150"
              height="150"
            />
            <div class="mt-3">
              <h4 class="placeholder col-10"></h4>
              <br />
              <p class="placeholder col-5"></p>
              <p class="placeholder col-9"></p>
              <div class="d-flex justify-content-between">
                <button class="btn btn-success disabled">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <button class="btn btn-danger disabled">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-3 placeholder-glow">
        <ul class="list-group list-group-flush">
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-3"></h6>
            <span class="text-secondary placeholder col-3"></span>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-5"></h6>
            <span class="text-secondary placeholder col-6"></span>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-5"></h6>
            <span class="text-secondary placeholder col-6"></span>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-3"></h6>
            <span class="text-secondary placeholder col-6"></span>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-5"></h6>
            <span class="text-secondary placeholder col-5"></span>
          </li>
        </ul>
      </div>
      <div class="card mt-3 placeholder-glow">
        <ul class="list-group list-group-flush">
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-4"></h6>
            <button
              class="btn btn-primary btn-sm disabled placeholder col-4"
            ></button>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-4"></h6>
            <button
              class="btn btn-primary btn-sm disabled placeholder col-4"
            ></button>
          </li>
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <h6 class="mb-0 placeholder col-4"></h6>
            <button
              class="btn btn-primary btn-sm disabled placeholder col-4"
            ></button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-8 placeholder-glow">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0 placeholder col-3"></h6>
            </div>
            <div class="col-sm-9 text-secondary placeholder col-2"></div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0 placeholder col-4"></h6>
            </div>
            <div class="col-sm-9 text-secondary placeholder col-3"></div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0 placeholder col-1"></h6>
            </div>
            <div class="col-sm-9 text-secondary placeholder col-3"></div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0 placeholder col-2"></h6>
            </div>
            <div class="col-sm-9 text-secondary placeholder col-3"></div>
          </div>
          <hr />
        </div>
      </div>

      <div class="row gutters-sm placeholder-glow">
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0 placeholder col-2"></h6>
                </div>
                <div class="col-sm-9 text-secondary placeholder col-3"></div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0 placeholder col-1"></h6>
                </div>
                <div class="col-sm-9 text-secondary placeholder col-3"></div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0 placeholder col-2"></h6>
                </div>
                <div class="col-sm-9 text-secondary placeholder col-3"></div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0 placeholder col-3"></h6>
                </div>
                <div class="col-sm-9 text-secondary placeholder col-3"></div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0 placeholder col-2"></h6>
                </div>
                <div class="col-sm-9 text-secondary placeholder col-3"></div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div class="row gutters-sm placeholder-glow">
        <div class="col-sm-6 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <h6 class="d-flex align-items-center mb-3">
                <i class="bi bi-person-check"></i> &nbsp;
                <span class="placeholder col-2"></span>
              </h6>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <h6 class="d-flex align-items-center mb-3">
                <i class="bi bi-person-check"></i> &nbsp;
                <span class="placeholder col-2"></span>
              </h6>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
              <small class="placeholder col-1"></small>
              <p class="placeholder col-12"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailPlaceholder",
};
</script>

<style scoped></style>
